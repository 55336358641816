import { useAppState } from "~/AppContext";
import { A } from "@solidjs/router";

export default function Footer() {
  const { t } = useAppState();

  return (
    <footer class="hidden w-full md:block">
      <nav class=" mb-10 mt-16 pb-6 text-sm text-center md:text-left fade-in max-w-screen-xl px-2 mx-auto md:flex flex-row hidden">
        <span class="text-gray-500 flex items-center no-underline hover:no-underlin px-2">
          &copy; Scixiv 2023
        </span>
        <span class="grow"></span>
        <A href="/about" class={`flex items-center px-2 py-2`}>
          {t("about")}
        </A>
      </nav>
    </footer>
  );
}
