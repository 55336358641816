const sdkConfig = {
  serverUrl:
    import.meta.env.VITE_CASDOOR_ENDPOINT || "https://door.casdoor.com",
  clientId: import.meta.env.VITE_CASDOOR_APPID || "app",
  appName: import.meta.env.VITE_CASDOOR_APPNAME || "app",
  organizationName: import.meta.env.VITE_CASDOOR_ORGNAME || "org name",
  redirectPath: "/api/signin",
  signinPath: "/api/signin",
  scope: "profile",
};

export default function CasdoorLogin({ title }: { title: string }) {
  const loginAct = async () => {
    const SDK = await import("~/lib/casdoor-sdk");
    const sdk = new SDK.default(sdkConfig);
    sdk.popupSignin(window.location.origin);
  };

  const silentAct = async () => {
    const SDK = await import("~/lib/casdoor-sdk");
    const sdk = new SDK.default(sdkConfig);
    sdk.silentSignin((suc) => console.log("suc", suc), console.log);
  };

  // <button onClick={silentAct}>silent</button>
  return (
    <button
      onClick={loginAct}
      class="text-black font-bold bg-gray-100 hover:bg-gray-300 rounded-sm text-xl px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600"
      data-sveltekit-preload-data="off"
    >
      {title}
    </button>
  );
}
